function StickyService(offsetTopFnc){
			if (typeof offsetTopFnc === 'undefined') {
				offsetTopFnc = function () {
					return 0;
				};
			}
		
			this._widgets = [];
			
			this._scrollMargin = 10;
			this._refreshTime = 500;
			this._refreshCount = 6;
		
			this._offsetTopFnc = offsetTopFnc;
			
			this._refreshCounter = 0;
		}
		
		StickyService.prototype.setOffsetTopFnc = function(offsetTopFnc){
			this._offsetTopFnc = offsetTopFnc;
			this.refresh();
		};
		
		StickyService.prototype.addStickyWidget = function(stickyWidget){
			this._widgets.push(stickyWidget);
				
			this._widgets.sort(function(a, b){
				var aPriority = a.epSticky('option', 'priority');
				var bPriority = b.epSticky('option', 'priority');
				
				return bPriority-aPriority;
			});
			
			var service = this;
			stickyWidget.on('epstickychange', function(event, data){
				service._onWidgetChange.apply(service, [stickyWidget, event, data]);
			});
			
			this._recalculateOffsets();
			
			this._setUpListener();
			
			this._setUpRefreshTimer();
		};
		
		StickyService.prototype._setUpListener = function(){
			var jWindow = $(window);
			jWindow.on('resize', this.refresh.bind(this));
			jWindow.on('load', this.refresh.bind(this));
		};
		
		StickyService.prototype._setUpRefreshTimer = function(){
			if(this._refreshCounter < this._refreshCount){
				setTimeout((function(){
					this.refresh();
					
					this._refreshCounter++;
					this._setUpRefreshTimer();
				}).bind(this), this._refreshTime);
			}
		};
		
		StickyService.prototype.refresh = function(){
			var widgets = this._widgets;
			for(var i = 0; i < widgets.length; i++){
				var widget = widgets[i];
				widget.epSticky('refresh');
			}
			
			this._recalculateOffsets();
		};
		
		StickyService.prototype._onWidgetChange = function(){
			this._recalculateOffsets();
		};
		
		StickyService.prototype._calculateDimensions = function(){
			var stickyItems = [];
				
			var widgets = this._widgets;
			var currentOffset = this._offsetTopFnc();
			for(var i = 0; i < widgets.length; i++){
				var widget = widgets[i];
				
				var stickyHeight = undefined;
				var isSticky = widget.epSticky('isSticky');
				if(isSticky){
					stickyHeight = widget.epSticky('stickyHeight');
				}
				
				stickyItems.push({
					isSticky: isSticky,
					stickyHeight: stickyHeight,
					offset: currentOffset,
					widget: widget
				});
				
				//Change offset for the next item
				if(isSticky){
					currentOffset = currentOffset + stickyHeight;
				}
			}
			
			return stickyItems;
		};
		
		StickyService.prototype._recalculateOffsets = function(stickyWidget, event, data){
			var stickyDimensions = this._calculateDimensions();
				
			for(var i = 0; i < stickyDimensions.length; i++){
				var stickyDimension = stickyDimensions[i];
				
				stickyDimension.widget.epSticky('option', 'offset', stickyDimension.offset);
			}
		};
		
		StickyService.prototype._getStickyOffset = function(){
			var offset = this._offsetTopFnc();
				
			var stickyDimensions = this._calculateDimensions();
			if(stickyDimensions.length !== 0){
				var lastStickyDimension = stickyDimensions[stickyDimensions.length - 1];
				
				offset = lastStickyDimension.offset;
				if(lastStickyDimension.isSticky){
					offset = offset + lastStickyDimension.stickyHeight;
				}
			}
			return offset;
		};
		
		StickyService.prototype.scrollTo = function(offset){
			var service = this;
				
			return $('html, body').stop().animate({
				duration: 1000,
				scrollTop: offset - (service._getStickyOffset() + service._scrollMargin),
			}).promise()
				.then(function(){
					//Nochmal einige Pixel mehr (StickyOffset kann sich verändert haben)
					return $('html, body').stop().animate({
						scrollTop: offset - (service._getStickyOffset() + service._scrollMargin),
					}, 100).promise();
				});
		};
		
		var service = new StickyService();
		
		$.widget('ep.epSticky', {
			options: {
				stickyClass: 'sticky',
				findContainerElmFunction: function(widgetElement){
					return widgetElement.parent();
				},
				getWatchedElm: function() {
					var elmCache = this._elmCache;

					return elmCache.container[0];
				},
				findStickyElmFunction: function(element){
					return element;
				},
				priority: undefined,
				offset: 0, //Private: Only set by the service
				watcherOffset: 0,
				isStickyEnabledFunc: function(){
					return !this.options.disabled;
				},
				stickyContainerHeightFunc: function(){
					return this.stickyHeight();
				},
				// Callbacks
				change: null
			},
			_create: function(){
				this._enabledBefore = !this.options.disabled;
				
				this._cacheElms();

				this._createElmWatcher();
				service.addStickyWidget(this.element);

				this._on(this.element, {
					'click': 'refresh',
				});
			},
			_createElmWatcher: function(){
				var component = this;
				
				var elm = this.options.getWatchedElm.apply(this, []);
				
				var elementWatcher = scrollMonitor.create(
					elm,
					component.options.offset + component.options.watcherOffset
				);
						
				elementWatcher.on('stateChange', function(){
					component._trigger('change', null, $.noop);
					
					component._refreshClassAndCss.apply(component, []);
				});
				
				component._elementWatcher = elementWatcher;
				
				component.refresh();
			},
			_change: $.noop,
			_cacheElms: function(){
				var element = this.element;
				
				var elmCache = {};
				elmCache.container = this.options.findContainerElmFunction(element);
				elmCache.sticky = this.options.findStickyElmFunction(element);
				
				this._elmCache = elmCache;
			},
			_onResize: function(){
				this._refreshWrapperHeight();
			},
			refresh: function(){
				this._refreshWrapperHeight();
				this._refreshClassAndCss();
				
				var isStickyEnabled = this.isStickyEnabled();
				if(this._enabledBefore !== isStickyEnabled){
					this._trigger('change', null, $.noop);
					
					this._enabledBefore = isStickyEnabled;
				}
			},
			_refreshClassAndCss: function(){
				var className = this.options.stickyClass;
				
				var elmCache = this._elmCache;
				var container = elmCache.container;
				var stickyElm = elmCache.sticky;
				if(this.isSticky()){
					stickyElm.css('top', this.options.offset);
					container.addClass(className);
				}else{
					container.removeClass(className);
					stickyElm.css('top', '');
				}
			},
			_refreshWrapperHeight: function(){
				if(typeof this._elementWatcher !== 'undefined'){
					var elmCache = this._elmCache;
					var containerElm = elmCache.container;
					
					if(this.isStickyEnabled()){
						var stickyContainerHeight = this.stickyContainerHeight();
						
						containerElm.css('height', stickyContainerHeight);
					}else{
						containerElm.css('height', '');
					}
				}
			},
			stickyHeight: function(){
				var elmCache = this._elmCache;
				return elmCache.sticky.outerHeight();
			},
			stickyContainerHeight: function(){
				return this.options.stickyContainerHeightFunc.apply(this, []);
			},
			isSticky: function(){
				var isSticky = this.isStickyEnabled();
				if(isSticky){
					isSticky = this._elementWatcher.isAboveViewport;
				}
				
				return isSticky;
			},
			isStickyEnabled: function(){
				return this.options.isStickyEnabledFunc.apply(this, []);
			},
			_setOption: function( key, value ) {
				var offsetBefore;
				if(key === 'offset'){
					offsetBefore = this.options.offset;
				}

				var watcherOffsetBefore;
				if(key === 'watcherOffset'){
					watcherOffsetBefore = this.options.watcherOffset;
				}
				
				this._super( key, value );
				
				if(
					(key === 'offset' && offsetBefore !== value) ||
					(key === 'watcherOffset' && watcherOffsetBefore !== value)
				){
					this._elementWatcher.destroy();

					this._createElmWatcher()
					this._refreshClassAndCss();
				}
			}
		});