(function() {

	var fetchLocalization = function(language, key) {
		var body = jQuery('body');
		var baseUrl = body.data('baseUrl');

		var i18nValue;

		$.ajax({
			url: baseUrl + 'i18nTranslation/' + key,
			type: 'GET',
			async: false,
			success: function (value) {
				i18nValue = value;
			}
		});
		return i18nValue;
	};

	var localize = function(key){
		var bodyLanguage = $('main[data-language-code]').data('language-code');
		return fetchLocalization(bodyLanguage, key);
	};

	window.ep = window.ep || {};
	window.ep.services = window.ep.services || {};
	window.ep.services.i18nService = {
		localize: localize
	};
})();