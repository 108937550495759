(function(){
	var serviceInstance = undefined;

	var NotificationService = function(){};
	
	NotificationService.prototype.showSuccess = function(message, title, buttons){
		return this._show({
			title: title,
			message: message,
			color: 'green',
			buttons: buttons
		});
	};
	
	NotificationService.prototype.showWarning = function(message, title, buttons){
		return this._show({
			title: title,
			message: message,
			color: 'red',
			buttons: buttons
		});
	};
	
	NotificationService.prototype._show = function(options){
		var dfd = jQuery.Deferred();
		
		if(typeof options.buttons === 'undefined'){
			options.buttons = [];
		}
		
		if(typeof options.title === 'undefined'){
			options.title = '';
		}
		
		options.onClosed = function () {
			dfd.resolve({});
		};
		
		iziToast.show(options);
		
		return dfd.promise();
	};

	function getService(){
		if(typeof serviceInstance === 'undefined'){
			serviceInstance = new NotificationService();
		}
		
		return serviceInstance;
	}

	//API
	function showSuccess(message, title, buttons){
		getService().showSuccess(message, title, buttons);
	}

	function showWarning(message, title, buttons){
		getService().showWarning(message, title, buttons);
	}

	window.ep = window.ep || {};
	window.ep.services = window.ep.services || {};
	window.ep.services.notificationService = {
		showSuccess: showSuccess,
		showWarning: showWarning
	};
})();