(function(){
	var ProductCompareService = function(){
		var body = jQuery('body');
		
		this._urls = {};
		
		this._isProductCompareEnabled = body.data('isProductCompareEnabled');
		if(this._isProductCompareEnabled){
			this._urls.add = body.data('productCompareAddUrl');
			this._urls.remove = body.data('productCompareRemoveUrl');
			this._urls.view = body.data('productCompareViewUrl');
		}
		
		this._limit = body.data('productCompareLimit');
		
		var services = window.ep.services;
		this._notificationService = services.notificationService;
		this._i18nService = services.i18nService;
		
		this._changeListeners = [];
	};
	
	ProductCompareService.prototype.add = function(productCode){
		var service = this;
		
		return this._enabledPromise()
		.then(function(){
			return jQuery.ajax({
				url: service._urls.add,
				method: 'POST',
				dataType : 'json',
				data: {
					productCode: productCode
				}
			});
		})
		.then(service._handleNotificationsThen.bind(service))
		.then(service._handleChangeThen.bind(service));
	};
	
	ProductCompareService.prototype.remove = function(productCode){
		var service = this;
		
		return this._enabledPromise()
		.then(function(){
			return jQuery.ajax({
				url: service._urls.remove,
				method: 'POST',
				dataType : 'json',
				data: {
					productCode: productCode
				}
			});
		})
		.then(service._handleNotificationsThen.bind(service))
		.then(service._handleChangeThen.bind(service));
	};
	
	ProductCompareService.prototype._handleNotificationsThen = function(data){
		var service = this;
		
		if(typeof data.errorMessage !== 'undefined'){
			this._notificationService.showWarning(data.errorMessage, undefined);
		}else if(typeof data.successMessage !== 'undefined'){
			var buttons = [];
			
			if(data.acceptedProducts.length > 1){
				var viewButton = '<button class="primary-button">' + this._i18nService.localize('cmsproductcomparedialogcomponent.compare') +'</button>';
				buttons.push([viewButton, function(){
					service.openComparePage.apply(service, []);
				}]);
			}
			
			this._notificationService.showSuccess(data.successMessage, undefined, buttons);
		}
		
		return data;
	};
	
	ProductCompareService.prototype._handleChangeThen = function(data){
		this.triggerChange.apply(this, [data.acceptedProducts]);
		
		if(typeof data.errorKey !== 'undefined'){
			var dfd = jQuery.Deferred();
			dfd.reject(data);
			
			return dfd.promise();
		}else{
			return data;
		}
	};
	
	
	ProductCompareService.prototype.change = function(listener){
		this._changeListeners.push(listener);
	};
	
	
	ProductCompareService.prototype.triggerChange = function(products){
		var changeListeners = this._changeListeners;
		for(var i=0; i < changeListeners.length; i++){
			var changeListener = this._changeListeners[i];
			
			changeListener(products);
		}
	};
	
	
	ProductCompareService.prototype.isEnabled = function(){
		return this._isProductCompareEnabled;
	};
	
	ProductCompareService.prototype._enabledPromise = function(){
		var dfd  = jQuery.Deferred();
		if(isEnabled()){
			dfd.resolve({});
		}else{
			dfd.reject({error: 'disabled'});
		}
		
		return dfd.promise();
	};
	
	ProductCompareService.prototype.getLimit = function(){
		return this._limit;
	};
	
	ProductCompareService.prototype.openComparePage = function(){
		var service = this;
		
		return this._enabledPromise()
		.then(function(){
			window.location = service._urls.view;
		});
	};

	var serviceInstance = undefined;
	var getService = function(){
		if(typeof serviceInstance === 'undefined'){
			serviceInstance = new ProductCompareService();
		}
		
		return serviceInstance;
	};


	//API

	var isEnabled = function(){
		return getService().isEnabled();
	};

	var add = function(productCode){
		return getService().add(productCode);
	};

	var remove = function(productCode){
		return getService().remove(productCode);
	};

	var change = function(listener){
		return getService().change(listener);
	};

	var limit = function(){
		return getService().getLimit();
	};

	var openComparePage = function(){
		return getService().openComparePage();
	};

	window.ep = window.ep || {};
	window.ep.services = window.ep.services || {};
	window.ep.services.productCompareService = {
		add: add,
		remove: remove,
		isEnabled: isEnabled,
		change: change,
		limit: limit,
		openComparePage: openComparePage
	};
})();